import React, { useState, useEffect } from 'react';
import { Layout, Menu, Slider } from 'antd'; // Import Slider
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import dzj from '../data/dzj';
import WizardModal from '../components/WizardModal'; // Import the new component

const { Header, Content, Sider } = Layout;

const DizangSutra = () => {
    const [selectedKey, setSelectedKey] = useState("1");
    const [fontSize, setFontSize] = useState(() => {
        return parseInt(localStorage.getItem('fontSize')) || 16; // Load font size from local storage
    });
    const [isWizardVisible, setIsWizardVisible] = useState(true); // Add this state

    useEffect(() => {
        localStorage.setItem('fontSize', fontSize); // Save font size to local storage
    }, [fontSize]);

    const handleMenuClick = e => {
        setSelectedKey(e.key);
    };

    const { title, paragraphs } = dzj[selectedKey];

    const handleWizardClose = () => {
        setIsWizardVisible(false);
    };

    return (
        <Layout>
            <Header style={{ position: 'fixed', width: '100%', zIndex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                    <span style={{ color: 'white', fontSize: '18px' }}>地藏菩萨本愿经</span>
                </div>
            </Header>
            <Layout>
                <Sider width={200} style={{ background: '#fff', position: 'fixed', height: '100vh', marginTop: '64px' }}>
                    <div style={{ height: 'calc(100vh - 144px)', overflow: 'auto' }}>
                        <Menu
                            mode="inline"
                            selectedKeys={[selectedKey]}
                            style={{ borderRight: 0 }}
                            onClick={handleMenuClick}
                        >
                            <Menu.Item key="1">忉利天宫神通品第一</Menu.Item>
                            <Menu.Item key="2">分身集会品第二</Menu.Item>
                            <Menu.Item key="3">观众生业缘品第三</Menu.Item>
                            <Menu.Item key="4">阎浮众生业感品第四</Menu.Item>
                            <Menu.Item key="5">地狱名号品第五</Menu.Item>
                            <Menu.Item key="6">如来赞叹品第六</Menu.Item>
                            <Menu.Item key="7">利益存亡品第七</Menu.Item>
                            <Menu.Item key="8">阎罗王众赞叹品第八</Menu.Item>
                            <Menu.Item key="9">称佛名号品第九</Menu.Item>
                            <Menu.Item key="10">校量布施功德缘品第十</Menu.Item>
                            <Menu.Item key="11">地神护法品第十一</Menu.Item>
                            <Menu.Item key="12">见闻利益品第十二</Menu.Item>
                            <Menu.Item key="13">嘱累人天品第十三</Menu.Item>
                            <Menu.Item key="14">赞</Menu.Item>
                            <Menu.Item key="15">补阙真言</Menu.Item>
                            <Menu.Item key="16">补阙圆满真言</Menu.Item>
                            <Menu.Item key="17">普回向真言</Menu.Item>
                            <Menu.Item key="18">七佛灭罪真言</Menu.Item>
                            <Menu.Item key="19">地藏菩萨灭定业真言</Menu.Item>
                            <Menu.Item key="20">回向偈</Menu.Item>
                            <Menu.Item key="21">三皈依</Menu.Item>
                        </Menu>
                    </div>
                    <div style={{ 
                        position: 'fixed', 
                        bottom: '0', 
                        width: '200px', 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center', 
                        background: '#fff', 
                        padding: '10px',
                        borderTop: '1px solid #f0f0f0'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                            <LeftOutlined onClick={() => { 
                                const prevKey = Math.max(1, parseInt(selectedKey) - 1).toString();
                                setSelectedKey(prevKey);
                                handleMenuClick({ key: prevKey });
                            }} style={{ fontSize: '24px', cursor: 'pointer' }} />
                            <RightOutlined onClick={() => { 
                                const nextKey = Math.min(21, parseInt(selectedKey) + 1).toString();
                                setSelectedKey(nextKey);
                                handleMenuClick({ key: nextKey });
                            }} style={{ fontSize: '24px', cursor: 'pointer' }} />
                        </div>
                        <Slider 
                            min={12} 
                            max={24} 
                            value={fontSize} 
                            onChange={setFontSize} 
                            style={{ width: '80%' }} 
                        />
                    </div>
                </Sider>
                <Content style={{ padding: '50px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#f9f4e6', minHeight: '100vh', marginLeft: '200px', marginTop: '20px' }}>
                    <div style={{ backgroundColor: 'white', padding: '30px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0,0,0,0.1)', border: '1px solid #8B4513' }}>
                        <h1 style={{ textAlign: 'center', margin: '0px 0 20px 0', color: '#8B4513' }}>{title}</h1>
                        {paragraphs.map((paragraph, index) => (
                            <p key={index} style={{ textIndent: '2em', lineHeight: '1.8', color: '#4A4A4A', fontSize: `${fontSize}px` }}>
                                {paragraph.includes('<ruby>') 
                                    ? <span dangerouslySetInnerHTML={{ __html: paragraph }} />
                                    : paragraph
                                }
                            </p>
                        ))}
                    </div>
                </Content>
            </Layout>
            <WizardModal 
                isVisible={isWizardVisible} 
                onClose={handleWizardClose} 
            />
        </Layout>
    );
};

export default DizangSutra;