import React, { useState, useEffect } from 'react';
import { Layout } from 'antd'; // 移除Menu的导入
import moment from 'moment'; // 添加moment库以处理日期
import axios from 'axios'; // 需要安装 axios: npm install axios
import { Link } from 'react-router-dom'; // 导入useNavigate和Link组件

const { Header, Content } = Layout; // 解构Layout组件

const Home = () => {
    const [specialDays, setSpecialDays] = useState({ today: false, tomorrow: false });

    useEffect(() => {
        const fetchSpecialDays = async () => {
            try {
                const response = await axios.get('https://specialday.api.dzwps.vber.me/check-special-days');
                if (response.data.error_code === 0) {
                    setSpecialDays({
                        today: response.data.is_today_special_day === 1,
                        tomorrow: response.data.is_tmr_special_day === 1
                    });
                }
            } catch (error) {
                console.error('获取十斋日信息失败:', error);
            }
        };

        fetchSpecialDays();
    }, []);

    const handleMenuClick = () => {
        window.location.href = 'https://vber.me'; // 跳转到外部链接
    };

    return (
        <Layout>
            <Header>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Link to="/recitation-card" style={{ color: 'white', cursor: 'pointer' }} target="_blank" rel="noopener noreferrer">念诵卡</Link>
                    <Link to="/dizang-sutra" style={{ color: 'white', cursor: 'pointer' }} target="_blank">地藏菩萨本愿经</Link>
                    <div style={{ color: 'white', cursor: 'pointer' }} onClick={handleMenuClick}>地藏菩萨心咒</div>
                    <div style={{ color: 'white', cursor: 'pointer' }} onClick={handleMenuClick}>具足水火吉祥光明大记明咒</div>
                    <div style={{ color: 'white' }}>
                        {moment().format('YYYY-MM-DD')}
                        <span style={{ marginLeft: '10px', color: specialDays.today ? 'yellow' : 'white' }}>
                            {specialDays.today ? '今日十斋日' : '今天非十斋日'}
                        </span>
                        <span style={{ marginLeft: '10px', color: specialDays.tomorrow ? 'lightgreen' : 'white' }}>
                            {specialDays.tomorrow ? '明日十斋日' : '明天非十斋日'}
                        </span>
                    </div>
                </div>
            </Header>
            <Content style={{ textAlign: 'center', padding: '50px' }}>
            <h1 style={{ fontSize: '60px', fontWeight: 'bold', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                南无大愿地藏王菩萨摩诃萨
            </h1>
            </Content>
        </Layout>
    );
};

export default Home;