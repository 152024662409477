import React from 'react';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import App from './App';
import reportWebVitals from './reportWebVitals'; // 添加此行
import { createRoot } from 'react-dom/client';
import './index.css'; // 添加此行

const root = createRoot(document.getElementById('root'));

// 设置网站标题
document.title = '地藏学院';

root.render(
    <ConfigProvider locale={zhCN}>
        <App />
    </ConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
