import React, { useState, useEffect, useRef, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { Select, InputNumber, Button, Tooltip, Layout, Typography, Space, Card, Modal } from 'antd';
import { SaveOutlined, BellOutlined } from '@ant-design/icons';
import styled, { keyframes, css } from 'styled-components';

// Import images
import dzpsImage from '../assets/images/card/dzps.jpg';
import gsypsImage from '../assets/images/card/gsyps.jpg';
import jgjImage from '../assets/images/card/jgj.jpg';
import lyzImage from '../assets/images/card/lyz.jpg';
import lzdmzImage from '../assets/images/card/lzdmz.jpg';
import ztpsImage from '../assets/images/card/ztps.jpg';

const { Option } = Select;
const { Header, Content, Sider } = Layout;
const { Title } = Typography;

const sentences = [
  "一切有为法，如梦幻泡影，如露亦如电，应作如是观。",
  "凡所有相，皆是虚妄。若见诸相非相，即见如来。",
  "若以色见我，以音声求我，是人行邪道，不能见如来。",
  "应无所住而生其心。",
  "如来者所从来，亦无所去，故名如来。",
  "诸行无常,是生灭法,生灭灭已,寂灭为乐。",
  "若人欲了知,三世一切佛,应观法界性,一切唯心造。",
  "心如工画师,能画诸世间,五蕴悉从生,无法而不造。",
  "佛法在世间,不离世间觉,离世觅菩提,恰如求兔角。",
  "善恶之报,如影随形,三世因果,循环不失。",
  "众生畏果,菩萨畏因。",
  "一念愤恨起,百万障门开。",
  "不依语言文字,教外别传,直指人心,见性成佛。",
  "千江有水千江月,万里无云万里天。"
];

const CardContainer = styled(Card)`
  width: 300px;
  height: 600px;
  background-size: cover;
  background-position: center;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  position: relative;
`;

// Add this new styled component
const BellButton = styled(Button)`
  position: absolute;
  bottom: 0px; // Adjust this value to move the button up or down
  right: -40px; // Adjust this value to move the button left or right
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
`;

const TopContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 15px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`;

const TitleContainer = styled.div`
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  grid-column: 2;
`;

const InfoText = styled.div`
  font-size: 0.9em;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const LeftInfo = styled(InfoText)`
  justify-self: start;
`;

const RightInfo = styled(InfoText)`
  justify-self: end;
`;

const SentenceContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 0px;
  text-align: center;
  font-size: 0.9em;
  color: white;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px; // 设置最小高度
`;

const ContentWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const CardWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7); }
  70% { box-shadow: 0 0 0 10px rgba(255, 215, 0, 0); }
  100% { box-shadow: 0 0 0 0 rgba(255, 215, 0, 0); }
`;

const GlowingCardWrapper = styled.div`
  display: inline-block;
  border-radius: 15px;
  ${({ isGlowing }) => isGlowing && css`
    animation: ${glowAnimation} 1s ease-out;
  `}
`;

const RecitationCard = () => {
  const [title, setTitle] = useState("南无地藏王菩萨");
  const [alreadyRecited, setAlreadyRecited] = useState(0);
  const [totalNumber, setTotalNumber] = useState(0);
  const [selectedSentence, setSelectedSentence] = useState(sentences[0]);
  const [goalReached, setGoalReached] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isGlowing, setIsGlowing] = useState(false);
  const [glowKey, setGlowKey] = useState(0);

  const cardRef = useRef(null);

  const [initialTotalNumber, setInitialTotalNumber] = useState(0);

  const updateBackgroundImage = useCallback(() => {
    let imageSource;
    switch (title) {
      case "南无地藏王菩萨":
        imageSource = dzpsImage;
        break;
      case "南无观世音菩萨":
        imageSource = gsypsImage;
        break;
      case "六字大明咒":
        imageSource = lzdmzImage;
        break;
      case "准提咒":
        imageSource = ztpsImage;
        break;
      case "楞严咒":
        imageSource = lyzImage;
        break;
      case "金刚经":
        imageSource = jgjImage;
        break;
      default:
        imageSource = dzpsImage; // Default image
    }
    setBackgroundImage(`url(${imageSource})`);
  }, [title]);

  const updateTotalNumber = useCallback((newTitle, customTotal = null) => {
    let total;
    if (customTotal !== null) {
      total = customTotal;
    } else {
      switch (newTitle) {
        case "南无地藏王菩萨":
        case "南无观世菩":
        case "六字大明咒":
          total = 1000;
          break;
        case "准提咒":
          total = 108;
          break;
        case "楞严咒":
        case "金刚经":
          total = 3;
          break;
        default:
          total = 0;
      }
    }
    setTotalNumber(total);
    setInitialTotalNumber(total); // Store the initial total number
  }, []);

  useEffect(() => {
    updateBackgroundImage();
    updateTotalNumber(title);
    
    // Load saved recitation count from localStorage
    const savedCount = localStorage.getItem('recitationCount');
    if (savedCount) {
      setAlreadyRecited(parseInt(savedCount, 10));
    }
  }, [title, updateTotalNumber, updateBackgroundImage]);

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleAlreadyRecitedChange = (value) => {
    const newValue = parseInt(value) || 0;
    setAlreadyRecited(newValue);
    // Save the new count to localStorage
    localStorage.setItem('recitationCount', newValue.toString());
    if (newValue >= totalNumber && totalNumber !== 0) {
      setGoalReached(true);
      setIsModalVisible(true); // 显示模态窗口而不是 alert
    } else {
      setGoalReached(false);
    }
  };

  const handleSentenceChange = (value) => {
    setSelectedSentence(sentences[value]);
  };

  const handleTotalNumberChange = (value) => {
    const newValue = parseInt(value) || 0;
    updateTotalNumber(title, newValue);
  };

  const handleBellClick = () => {
    if (goalReached) return; // 如果已达到目标，不再累加

    setGlowKey(prevKey => prevKey + 1);
    setIsGlowing(true);
    setTimeout(() => setIsGlowing(false), 1000);

    setAlreadyRecited(prevCount => {
      const newCount = prevCount + 1;
      // Save the new count to localStorage
      localStorage.setItem('recitationCount', newCount.toString());
      if (newCount >= totalNumber && totalNumber !== 0) {
        setGoalReached(true);
        setIsModalVisible(true);
      }
      return newCount;
    });
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleReset = () => {
    setAlreadyRecited(0);
    // Clear the saved count from localStorage
    localStorage.removeItem('recitationCount');
    setTotalNumber(initialTotalNumber);
    setGoalReached(false);
    setIsModalVisible(false);
  };

  const saveImage = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current).then(canvas => {
        const link = document.createElement('a');
        link.download = 'recitation_card.png';
        link.href = canvas.toDataURL();
        link.click();
      });
    }
  };

  return (
    <Layout>
      <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Title level={2} style={{ color: 'white', margin: 0 }}>诵经卡片生成器</Title>
        <Tooltip title="保存图片">
          <Button onClick={saveImage} icon={<SaveOutlined />} type="primary">
            保存图片
          </Button>
        </Tooltip>
      </Header>
      <Layout>
        <Sider width={300} theme="light">
          <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
            <Title level={4}>卡片设置</Title>
            <Select value={title} onChange={handleTitleChange} style={{ width: '100%' }}>
              <Option value="南无地藏王菩萨">南无地藏王菩萨</Option>
              <Option value="南无观世音菩萨">南无观世音菩萨</Option>
              <Option value="六字大明咒">六字大明咒</Option>
              <Option value="准提咒">准提咒</Option>
              <Option value="楞严咒">楞严咒</Option>
              <Option value="金刚经">金刚经</Option>
            </Select>
            <InputNumber
              style={{ width: '100%' }}
              value={alreadyRecited}
              min={0}
              onChange={handleAlreadyRecitedChange}
              addonBefore="已诵数量"
            />
            <InputNumber
              style={{ width: '100%' }}
              value={totalNumber}
              min={0}
              onChange={handleTotalNumberChange}
              addonBefore="目标总数"
            />
            <Select 
              defaultValue={0} // Set default value to the index of the first sentence
              onChange={handleSentenceChange} 
              style={{ width: '100%' }}
            >
              {sentences.map((sentence, index) => (
                <Option key={index} value={index}>{sentence}</Option>
              ))}
            </Select>
          </Space>
        </Sider>
        <ContentWrapper>
          <Content style={{ padding: '10px' }}>
            <GlowingCardWrapper isGlowing={isGlowing} key={glowKey}>
              <CardWrapper>
                <CardContainer style={{ backgroundImage: backgroundImage }} ref={cardRef}>
                  <TopContainer>
                    <LeftInfo>{alreadyRecited} / {totalNumber}</LeftInfo>
                    <TitleContainer>{title}</TitleContainer>
                    <RightInfo>{new Date().toISOString().split('T')[0]}</RightInfo>
                  </TopContainer>
                  <SentenceContainer>{selectedSentence}</SentenceContainer>
                </CardContainer>
                <BellButton 
                  icon={<BellOutlined />} 
                  shape="circle" 
                  size="large"
                  onClick={handleBellClick}
                  disabled={goalReached} // 当达到目标时禁用按钮
                />
              </CardWrapper>
            </GlowingCardWrapper>
          </Content>
        </ContentWrapper>
      </Layout>
      <Modal
        title="恭喜"
        open={isModalVisible}
        onOk={handleReset}
        onCancel={handleModalOk}
        okText="重置"
        cancelText="确认"
      >
        <p>恭喜您已达到目标！</p>
      </Modal>
    </Layout>
  );
};

export default RecitationCard;
