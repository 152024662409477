import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // 修改此行
import Home from './pages/Home';
import DizangSutra from './pages/DizangSutra';
import RecitationCard from './pages/RecitationCard'; // 添加此行

function App() {
  return (
    <Router>
      <Routes> {/* 修改此行 */}
        <Route path="/" element={<Home />} /> {/* 修改此行 */}
        <Route path="/dizang-sutra" element={<DizangSutra />} /> {/* 新增此行 */}
        <Route path="/recitation-card" element={<RecitationCard />} /> {/* 添加此行 */}
        {/* 其他路由 */}
      </Routes> {/* 修改此行 */}
    </Router>
  );
}

export default App;
