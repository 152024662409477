import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button } from 'antd';

const WizardModal = ({ isVisible, onClose }) => {
    const [step, setStep] = useState(0);

    const steps = [
        { title: "准备", content: "读地藏经前，请先净身、洗手、刷牙。" },
        { title: "燃香", content: "请燃香" },
        {
            title: "香炉赞",
            content: "炉香乍热。法界蒙薰。\n诸佛海会悉遥闻。\n 随处结祥云。诚意方殷。诸佛现全身。\n\n南无香云盖菩萨摩诃萨\n南无香云盖菩萨摩诃萨\n南无香云盖菩萨摩诃萨"
        },
        {
            title: "净口业真言(三遍)",
            content: "<ruby>唵&nbsp;<rt>ǒn</rt></ruby><ruby>修<rt>xiū</rt></ruby><ruby>唎<rt>lì</rt>&nbsp;</ruby><ruby>修<rt>xiū</rt></ruby><ruby>唎<rt>lì</rt>&nbsp;</ruby><ruby>摩<rt>mó</rt></ruby><ruby>诃<rt>hē</rt></ruby><ruby>修<rt>xiū</rt></ruby><ruby>唎<rt>lì</rt>&nbsp;</ruby><ruby>修<rt>xiū</rt></ruby><ruby>修<rt>xiū</rt></ruby><ruby>唎<rt>lì</rt></ruby>&nbsp;<ruby>萨<rt>suō</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>诃<rt>hē</rt></ruby>"
        },
        {
            title: "净意业真言(三遍)",
            content: "<ruby>唵<rt>ǒn</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>日<rt>zǐ</rt></ruby><ruby>啰<rt>là</rt></ruby><ruby>怛<rt>dá</rt></ruby><ruby>诃<rt>hē</rt></ruby><ruby>贺<rt>hè</rt></ruby><ruby>斛<rt>hú</rt></ruby>"
        },
        {
            title: "净身业真言(三遍)",
            content: "<ruby>唵<rt>ǒn</rt></ruby>，<ruby>修<rt>xiū</rt></ruby><ruby>多<rt>duō</rt></ruby><ruby>唎<rt>lì</rt></ruby>，<ruby>修<rt>xiū</rt></ruby><ruby>多<rt>duō</rt></ruby><ruby>唎<rt>lì</rt></ruby>，<ruby>修<rt>xiū</rt></ruby><ruby>摩<rt>mó</rt></ruby><ruby>唎<rt>lì</rt></ruby>，<ruby>修<rt>xiū</rt></ruby><ruby>摩<rt>mó</rt></ruby><ruby>摩<rt>mó</rt></ruby><ruby>唎<rt>lì</rt></ruby>，<ruby>萨<rt>suō</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>诃<rt>hē</rt></ruby>。"
        },
        {
            title: "净三业真言(三遍)",
            content: "<ruby>唵<rt>ǒn</rt>，</ruby><ruby>娑<rt>suō</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>秫<rt>shú</rt></ruby><ruby>驮<rt>tuó</rt></ruby>，<ruby>娑<rt>suō</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>达<rt>dá</rt></ruby><ruby>摩<rt>mó</rt></ruby><ruby>娑<rt>suō</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>嚩<rt>wá</rt></ruby><ruby>秫<rt>shú</rt></ruby><ruby>度<rt>duó</rt></ruby><ruby>憾<rt>hàn</rt></ruby>。"
        },
        {
            title: "安土地真言(三遍)",
            content: "<ruby>南<rt>nán</rt></ruby><ruby>无<rt>mó</rt></ruby><ruby>三<rt>sān</rt></ruby><ruby>满<rt>mǎn</rt></ruby><ruby>多<rt>duō</rt></ruby>，<ruby>母<rt>mǔ</rt></ruby><ruby>驮<rt>tuó</rt></ruby><ruby>喃<rt>nán</rt></ruby>，<ruby>唵<rt>ōng</rt></ruby>，<ruby>度<rt>dù</rt></ruby><ruby>噜<rt>lū</rt></ruby><ruby>度<rt>dù</rt></ruby><ruby>噜<rt>lū</rt></ruby><ruby>地<rt>dì</rt></ruby><ruby>尾<rt>wěi</rt></ruby><ruby>娑<rt>suō</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>诃<rt>hē</rt></ruby>。"
        },

        {
            title: "普供养真言(三遍)",
            content: "<ruby>唵<rt>ǒn</rt></ruby>，<ruby>誐<rt>yé</rt></ruby><ruby>誐<rt>yé</rt></ruby><ruby>曩<rt>nǎnɡ</rt></ruby>，<ruby>三<rt>sān</rt></ruby><ruby>婆<rt>pó</rt></ruby><ruby>嚩<rt>wá</rt></ruby>，<ruby>伐<rt>fá</rt></ruby><ruby>日<rt>zǐ</rt></ruby><ruby>啰<rt>là</rt></ruby><ruby>斛<rt>hú</rt></ruby>"
        },
        {
            title: "觉林菩萨偈",
            content: "譬如工画师 分布诸彩色 虚妄取异相 大种无差别 \n大种中无色 色中无大种 亦不离大种 而有色可得 \n心中无彩画 彩画中无心 然不离于心 有彩画可得 \n彼心恒不住 无量难思议 示现一切色 各各不相知 \n譬如工画师 不能知自心 而由心故画 诸法性如是 \n心如工画师 能画诸世间 五蕴悉从生 无法而不造 \n如心佛亦尔 如佛众生然 应知佛与心 体性皆无尽 \n若人知心行 普造诸世间 是人则见佛 了佛真实性 \n心不住于身 身亦不住心 而能作佛事 自在未曾有 \n若人欲了知 三世一切佛 应观法界性 一切唯心造 \n"
        },
        {
            title: "地藏菩萨偈",
            content: "稽首本然净心地，无尽佛藏大慈尊。\n南方世界涌香云，香雨花云及花雨。\n宝雨宝云无数种，为祥为瑞遍庄严。\n天人问佛是何因，佛言地藏菩萨至。\n三世如来同赞叹，十方菩萨共皈依。\n我今宿植善因缘，称扬地藏真功德。\n慈因积善，誓救众生，手中金锡，振开地狱之门；\n掌上明珠，光摄大千世界。智慧音里，吉祥云中，\n为阎浮提苦众生；作大证明功德主。\n大慈大愿，大圣大慈，本尊地藏菩萨摩诃萨。"
        },
        {
            title: "开经偈",
            content: "无上甚深微妙法，百千万劫难遭遇。\n我今见闻得受持，愿解如来真实义。"
        }
    ];

    // 新增函数：检查内容是否包含<ruby>标签
    const containsRubyTag = (content) => {
        return content.includes('<ruby>');
    };

    const handleNext = useCallback(() => {
        setStep(prevStep => prevStep + 1);
    }, []);

    const handlePrev = useCallback(() => {
        setStep(prevStep => prevStep - 1);
    }, []);

    const handleClose = useCallback(() => {
        setStep(0);
        onClose();
    }, [onClose]);

    // Add useEffect hook for keyboard controls
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (isVisible) {
                if (event.key === 'ArrowLeft') {
                    handlePrev();
                } else if (event.key === 'ArrowRight') {
                    if (step === steps.length - 1) {
                        handleClose();
                    } else {
                        handleNext();
                    }
                }
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        // Clean up the event listener
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [isVisible, step, handlePrev, handleNext, handleClose, steps.length]);

    return (
        <Modal
            title={steps[step].title}
            open={isVisible}
            onCancel={handleClose}
            footer={
                <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button key="back" onClick={handlePrev} disabled={step === 0}>
                        上一步
                    </Button>
                    <Button key="next" type="primary" onClick={step === steps.length - 1 ? handleClose : handleNext}>
                        {step === steps.length - 1 ? '开始念诵' : '下一步'}
                    </Button>
                </div>
            }
            style={{
                top: '50%',
                transform: 'translateY(-50%)',
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto'
            }}
        >
            {containsRubyTag(steps[step].content) ? (
                <p 
                    style={{ whiteSpace: 'pre-line', fontSize: '30px' }}
                    dangerouslySetInnerHTML={{ __html: steps[step].content }}
                />
            ) : (
                <p style={{ whiteSpace: 'pre-line', fontSize: '18px' }}>{steps[step].content}</p>
            )}
        </Modal>
    );
};

export default WizardModal;